/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable dot-notation */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  applyCapitalization,
  applyShadesOnBookedColumn,
  applySharedColumnProperties,
  applyStyleOnChildHeaderCell,
  gridStateHelper,
  gridStyleHelper,
  onColumnVisibleEvent,
  onFilterChangedEvent,
  onGridPreDestroyedEvent,
  onGridReadyEvent,
  onSelectionChangedEvent,
  onSortChangedEvent,
  onStateUpdatedEvent,
} from '../../utils';
import { GetGridStateAPI } from 'api';
import { Button } from 'op2mise-react-widgets/Button';
import config from '../../ag-config.properties.json';
import {
  FooterTemplate,
  LoaderTemplate,
  LoaderTemplateForRow,
  OverlayTemplate,
} from '../../Templates';
import { Themes } from '../../'
import '../../stylesheets/ag-theme-op2mise-2.css';
import '../../stylesheets/ag-theme-op2mise.css';
import '../../stylesheets/ag-theme-op2mise-simple.css';
import '../../DataGrid.css';

export function DualGrid(props) {
  const { Constants } = config;
  const { leftGrid, rightGrid } = props;

  // Refs
  const leftGridRef = React.useRef(null);
  const rightGridRef = React.useRef(null);

  // States
  const [columnIsWithChildren, setColumnIsWithChildren] = React.useState(false);
  // Left Grid States
  const [leftGridColumnDefs, setLeftGridColumnDefs] = React.useState(leftGrid.columns);
  const [leftGridData, setLeftGridData] = React.useState(leftGrid.rows);
  const [leftGridPageSize, setLeftGridPageSize] = React.useState(leftGrid.paginationPageSize);
  const [leftGridRowsToDisplay, setLeftGridRowsToDisplay] = React.useState(leftGrid.rows.length);
  const [leftGridSelectedRows, setLeftGridSelectedRows] = React.useState(0);
  const [leftGridState, setLeftGridState] = React.useState(null);
  const [isLeftGridFirstDataRendered, setIsLeftGridFirstDataRendered] = React.useState(false);
  const [isLeftGridReady, setIsLeftGridReady] = React.useState(false);
  const [isLeftGridRowEmptyAfterFilter, setIsLeftGridRowEmptyAfterFilter] = React.useState(false);
  const [isLeftGridRowStillProcessing, setIsLeftGridRowStillProcessing] = React.useState(false);
  const [isLeftGridStillProcessing, setIsLeftGridStillProcessing] = React.useState(true);
  // Right Grid States
  const [rightGridColumnDefs, setRightGridColumnDefs] = React.useState(rightGrid.columns);
  const [rightGridData, setRightGridData] = React.useState(rightGrid.rows);
  const [rightGridPageSize, setRightGridPageSize] = React.useState(rightGrid.paginationPageSize);
  const [rightGridRowsToDisplay, setRightGridRowsToDisplay] = React.useState(rightGrid.rows.length);
  const [rightGridSelectedRows, setRightGridSelectedRows] = React.useState(0);
  const [rightGridState, setRightGridState] = React.useState(null);
  const [isRightGridFirstDataRendered, setIsRightGridFirstDataRendered] = React.useState(false);
  const [isRightGridReady, setIsRightGridReady] = React.useState(false);
  const [isRightGridRowEmptyAfterFilter, setIsRightGridRowEmptyAfterFilter] = React.useState(false);
  const [isRightGridRowStillProcessing, setIsRightGridRowStillProcessing] = React.useState(false);
  const [isRightGridStillProcessing, setIsRightGridStillProcessing] = React.useState(true);

  const client = React.useMemo(() => (isLeftGridReady && isRightGridReady), [isLeftGridReady, isRightGridReady]);

  // Provides stylesheet on the Grid Container
  const gridWrapperStyle = React.useCallback((buffer) => gridStyleHelper({ gridHeightBuffer: buffer }), []);

  const handleDefaultColDefs = React.useCallback((grid) => {
    /**
     * Dynamically sets default column definitions for ag-Grid.
     * Applies the given default settings to the `defaultColDef` property of the provided `gridOptions` object.
     */
    const gridObject = grid;

    return {
      editable: gridObject.editable,
      filter: gridObject.filter,
      lockPinned: gridObject.lockPinned,
      resizable: gridObject.resizable,
      sortable: gridObject.sortable,
      minWidth: Constants.ColDef.minWidth,
    }
  }, []);

  // Auto run for capitalizing header name and header tooltip texts
  const handleCapitalization = React.useCallback((columnInstance) =>
    applyCapitalization({ columnInstance })
    , []);

  // Auto run for setting background color (light/dark colors) on columns that contains `book` in the `field` column attribute
  const handleBookedColumn = React.useCallback((columnInstance) =>
    applyShadesOnBookedColumn({ columnInstance, disableHighlightOnBookedColumn: leftGrid.disableHighlightOnBookedColumn, setColumnIsWithChildren })
    , [leftGrid.disableHighlightOnBookedColumn]);

  // Auto run for setting background color (pine-gree color) on CHILD header cells
  const handleChildHeaderCell = React.useCallback((columnInstance) =>
    applyStyleOnChildHeaderCell({ columnInstance })
    , []);

  // Auto run for memoized callback to apply shared column properties accross multiple columns.
  const handleSharedColumnProperties = React.useCallback((columnInstance) =>
    applySharedColumnProperties({ columnInstance, sharedColumnProperties: leftGrid.sharedColumnProperties })
    , [leftGrid.sharedColumnProperties]);

  const executeAllAutoRuns = React.useCallback(() => {
    // Executes all auto-run functions
    const leftGridColumnInstance = leftGridColumnDefs;
    const rightGridColumnInstance = rightGridColumnDefs;
    const autoRuns = [
      handleBookedColumn,
      handleCapitalization,
      handleChildHeaderCell,
      handleSharedColumnProperties,
    ];
    const refactoredLeftGridColumns = autoRuns.reduce((column, func) => func(column), leftGridColumnInstance);
    const refactoredRightGridColumns = autoRuns.reduce((column, func) => func(column), rightGridColumnInstance);
    setLeftGridColumnDefs(refactoredLeftGridColumns);
    setRightGridColumnDefs(refactoredRightGridColumns);
  }, [
    leftGridColumnDefs,
    rightGridColumnDefs,
    handleBookedColumn,
    handleCapitalization,
    handleChildHeaderCell,
    handleSharedColumnProperties,
  ]);

  // Re-hydrate rowdata when filtering causes the grid to be empty.
  // Render a row loader before and after grid becomes empty.
  const rehydrateRightGridRows = React.useCallback((params) => {
    if (isRightGridRowEmptyAfterFilter) {
      setIsRightGridRowEmptyAfterFilter(false);
      setIsRightGridRowStillProcessing(true);
      setTimeout(() => setIsRightGridRowStillProcessing(false), 200);
    }

    if (!params.api.rowModel.rowsToDisplay.length) {
      setIsRightGridRowEmptyAfterFilter(true);
      setIsRightGridRowStillProcessing(true);
      setTimeout(() => setIsRightGridRowStillProcessing(false), 200);
    }
  }, [isRightGridRowEmptyAfterFilter]);

  // Re-hydrate rowdata when filtering causes the grid to be empty.
  // Render a row loader before and after grid becomes empty.
  const rehydrateLeftGridRows = React.useCallback((params) => {
    if (!params.api.rowModel.rowsToDisplay.length) {
      setIsLeftGridRowEmptyAfterFilter(true);
      setIsLeftGridRowStillProcessing(true);
      setTimeout(() => setIsLeftGridRowStillProcessing(false), 200);
    }

    if (isLeftGridRowEmptyAfterFilter) {
      setIsLeftGridRowEmptyAfterFilter(false);
      setIsLeftGridRowStillProcessing(true);
      setTimeout(() => setIsLeftGridRowStillProcessing(false), 200);
    }
  }, [isLeftGridRowEmptyAfterFilter]);

  // Event handle for: onFilterChanged event
  const handleOnFilterChanged = React.useCallback((params, position) => {
    const { gridRef, onFilterChanged, setRowsToDisplay } = position === 'left'
      ? { gridRef: leftGridRef, onFilterChanged: leftGrid.onFilterChanged, setRowsToDisplay: setLeftGridRowsToDisplay }
      : { gridRef: rightGridRef, onFilterChanged: rightGrid.onFilterChanged, setRowsToDisplay: setRightGridRowsToDisplay };

    if (position === 'left') {
      rehydrateLeftGridRows(params);
    } else {
      rehydrateRightGridRows(params);
    }

    onFilterChangedEvent({
      params,
      gridRef,
      onFilterChanged,
      setRowsToDisplay,
    });
  }, [isLeftGridRowEmptyAfterFilter, isRightGridRowEmptyAfterFilter]);

  // Event handler for: onSelectionChanged event
  const handleOnSelectionChanged = React.useCallback((params, position) => {
    const { gridRef, onSelectionChanged, setSelectedRows } = position === 'left'
      ? { gridRef: leftGridRef, onSelectionChanged: leftGrid.onSelectionChanged, setSelectedRows: setLeftGridSelectedRows }
      : { gridRef: rightGridRef, onSelectionChanged: rightGrid.onSelectionChanged, setSelectedRows: setRightGridSelectedRows };

    // WORKAROUND: THIS BLOCK CLOSES ALL EXPANDED ROWS ON THE OTHER GRID
    // Consider removing this block if we want to expand two Detail Rows on Both Grids
    if (position === 'left') {
      rightGridRef.current.api.rowModel.rowsToDisplay.map(data => {
        data.expanded = false;
        return data;
      });
    } else {
      leftGridRef.current.api.rowModel.rowsToDisplay.map(data => {
        data.expanded = false;
        return data;
      });
    }

    onSelectionChangedEvent({
      params,
      gridRef,
      onSelectionChanged,
      setSelectedRows,
    });
  }, []);

  // Event handler for: onGridReady event
  const handleOnLeftGridReady = React.useCallback((params) => {
    onGridReadyEvent({
      params,
      onGridReady: leftGrid.onGridReady,
      gridRef: leftGridRef,
    });
    setIsLeftGridReady(true);
  }, [leftGrid.onGridReady]);

  const handleOnRightGridReady = React.useCallback((params) => {
    onGridReadyEvent({
      params,
      onGridReady: rightGrid.onGridReady,
      gridRef: rightGridRef,
    });
    setIsRightGridReady(true);
  }, [rightGrid.onGridReady]);

  // Event handler for: onSortChanged event
  const handleOnSortChanged = React.useCallback((params, position) => {
    const { gridRef, onSortChanged } = position === 'left'
      ? { gridRef: leftGridRef, onSortChanged: leftGrid.onSortChanged }
      : { gridRef: rightGridRef, onSortChanged: rightGrid.onSortChanged };

    onSortChangedEvent({ params, gridRef, onSortChanged });
  }, []);

  // Event handler for: onStateUpdated event
  const handleOnStateUpdated = React.useCallback((params, position) => {
    const { gridRef, onStateUpdated } = position === 'left'
      ? { gridRef: leftGridRef, onStateUpdated: leftGrid.onStateUpdated }
      : { gridRef: rightGridRef, onStateUpdated: rightGrid.onStateUpdated };

    onStateUpdatedEvent({ params, gridRef, onStateUpdated });
  }, []);

  // Event handler for: onGridPreDestroyed event
  const handleOnLeftGridPreDestroyed = React.useCallback((params) => {
    onGridPreDestroyedEvent({
      params,
      gridName: leftGrid.gridName,
      onGridPreDestroyed: leftGrid.onGridPreDestroyed,
      gridRef: leftGridRef,
    });
  }, []);

  const handleOnRightGridPreDestroyed = React.useCallback((params) => {
    onGridPreDestroyedEvent({
      params,
      gridName: rightGrid.gridName,
      onGridPreDestroyed: rightGrid.onGridPreDestroyed,
      gridRef: rightGridRef,
    });
  }, []);

  // Event handler for: onColumnVisible event
  const handleOnColumnvisible = React.useCallback((params, position) => {
    const { columnDefinition, gridRef, onColumnVisible } = position === 'left'
      ? { onColumnVisible: leftGrid.onColumnVisible, gridRef: leftGridRef, columnDefinition: leftGridColumnDefs }
      : { onColumnVisible: rightGrid.onColumnVisible, gridRef: rightGridRef, columnDefinition: rightGridColumnDefs };

    onColumnVisibleEvent({
      params,
      columnDefinition,
      gridRef,
      onColumnVisible,
    });
  }, [leftGridColumnDefs, rightGridColumnDefs]);

  const getRowId = React.useCallback((params) => {
    const rowId = params.data.id;
    return String(rowId);
  });

  const updateRowData = React.useCallback(() => {
    let leftGridRowTimer;
    let rightGridRowTimer;

    if (client) {
      setIsLeftGridRowStillProcessing(true);
      setIsRightGridRowStillProcessing(true);
    }

    leftGridRowTimer = setTimeout(() => setIsLeftGridRowStillProcessing(false), 300);
    rightGridRowTimer = setTimeout(() => setIsRightGridRowStillProcessing(false), 300);

    return () => {
      clearTimeout(leftGridRowTimer);
      clearTimeout(rightGridRowTimer);
    }
  }, [client]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for invoking all auto-run functions
     * Auto-run functions are customized features.
     * See: /utils/autoRuns
     */

    executeAllAutoRuns();
  }, []);

  React.useEffect(() => {
    /*
     * Side-effect for fetching Grid State
     * Pre-configures state before loading the grid into the Client
     */

    const fetchGridState = (grid, setGridState, gridRef) => {
      if (grid.gridName) {
        GetGridStateAPI({
          queryParams: { gridName: grid.gridName },
          onSuccess: (res) => {
            const state = res.state ? JSON.parse(res.state) : {};
            setGridState(state);
            if (res.state) {
              gridStateHelper(state, gridRef);
            }
          },
        });
      }
    };

    fetchGridState(leftGrid, setLeftGridState, leftGridRef);
    fetchGridState(rightGrid, setRightGridState, rightGridRef);
  }, []);

  React.useEffect(() => {
    /*
     * Side-effect responsible for setting up column definition for the Grid
     */

    leftGridRef.current?.api?.setGridOption('columnDefs', leftGridColumnDefs);
    rightGridRef.current?.api?.setGridOption('columnDefs', rightGridColumnDefs);
  }, [leftGrid.columns, rightGrid.columns]);

  React.useEffect(() => {
    /*
     * Left Grid Loader
     * Side-effect responsible for Invoking Loading effect from external factor (API call)
     * Decides whether to use Grid level or Row level loaders
     */

    let timerForGrid;
    let timerForRow;
    const isLeftGridEstablished = isLeftGridFirstDataRendered;
    const isLeftGridLoadingExternally = leftGrid.loading;
    const isLeftGridLoadingInternally = !isLeftGridReady;
    const isLeftGridStateFetched = leftGridState;
    const shouldLeftGridLoadWhenLoadingExternally = isLeftGridLoadingExternally && !isLeftGridEstablished;

    if (isLeftGridLoadingExternally && !shouldLeftGridLoadWhenLoadingExternally) {
      setIsLeftGridRowStillProcessing(true);
    } else {
      timerForRow = setTimeout(() => setIsLeftGridRowStillProcessing(false), 300);
    }

    if (!shouldLeftGridLoadWhenLoadingExternally && !isLeftGridLoadingInternally && isLeftGridStateFetched) {
      timerForGrid = setTimeout(() => setIsLeftGridStillProcessing(false), 300);
    } else {
      setIsLeftGridStillProcessing(true);
    }

    return () => {
      clearTimeout(timerForGrid);
      clearTimeout(timerForRow);
    };
  }, [
    isLeftGridFirstDataRendered,
    isLeftGridReady,
    leftGridState,
    leftGrid.loading,
  ]);

  React.useEffect(() => {
    /*
     * Right Grid Loader
     * Side-effect responsible for Invoking Loading effect from external factor (API call)
     * Decides whether to use Grid level or Row level loaders
     */

    let timerForGrid;
    let timerForRow;
    const isRightGridEstablished = isRightGridFirstDataRendered;
    const isRightGridLoadingExternally = rightGrid.loading;
    const isRightGridLoadingInternally = !isRightGridReady;
    const isRightGridStateFetched = rightGridState;
    const shouldRightGridLoadBasedOnExternalLoading = isRightGridLoadingExternally && !isRightGridEstablished;

    if (rightGrid.loading && !shouldRightGridLoadBasedOnExternalLoading) {
      setIsRightGridRowStillProcessing(true);
    } else {
      timerForRow = setTimeout(() => setIsRightGridRowStillProcessing(false), 300);
    }

    if (!shouldRightGridLoadBasedOnExternalLoading && !isRightGridLoadingInternally && isRightGridStateFetched) {
      timerForGrid = setTimeout(() => setIsRightGridStillProcessing(false), 300);
    } else {
      setIsRightGridStillProcessing(true);
    }

    return () => {
      clearTimeout(timerForGrid);
      clearTimeout(timerForRow);
    };
  }, [
    isRightGridFirstDataRendered,
    rightGridState,
    isRightGridReady,
    rightGrid.loading,
  ]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for hiding the default overlay message for empty rows
     * Prevents overlapping with BootstrapSpinner
     */

    if (!isLeftGridReady || leftGrid.loading) {
      if (leftGridRef.current?.api) leftGridRef.current?.api.hideOverlay();
    }

    if (!isRightGridReady || rightGrid.loading) {
      if (rightGridRef.current?.api) rightGridRef.current?.api.hideOverlay();
    }
  }, [
    isLeftGridReady,
    isRightGridReady,
    leftGrid.loading,
    rightGrid.loading,
  ]);

  React.useEffect(() => {
    /*
     * Side-effect responsible for setting up row-data for the Grid
     */

    updateRowData();

    const leftGridRowData = leftGrid.rows;
    const leftGridRowDataLength = leftGridRowData.length;
    const rightGridRowData = rightGrid.rows;
    const rightGridRowDataLength = rightGridRowData.length;
    // Re-assign values on the grid's rowData when arrays are mutated
    setLeftGridData(leftGridRowData);
    setRightGridData(rightGridRowData);

    // Updates the number of rows label located in the Footer template
    setLeftGridRowsToDisplay(leftGridRowDataLength);
    setRightGridRowsToDisplay(rightGridRowDataLength);

    if (leftGridRowDataLength > 200) {
      // Dynamically set a new page size if number of rows start to exceed 200
      const leftGridPageSize = (Math.ceil(leftGridRowDataLength / 100) * 100);
      setLeftGridPageSize(leftGridPageSize);
    };

    if (rightGridRowDataLength > 200) {
      // Dynamically set a new page size if number of rows start to exceed 200
      const rightGridPageSize = (Math.ceil(rightGridRowDataLength / 100) * 100);
      setRightGridPageSize(rightGridPageSize);
    };

  }, [rightGrid.rows, leftGrid.rows, updateRowData]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ height: '100%', flex: 5 }}>
          <span style={{ color: '#6d6d73', fontSize: '14px', fontWeight: 400, marginBottom: '5px' }}>{leftGrid.gridLabel}</span>
          <div className={props.theme === Themes.PRIMARY ? columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise' : 'ag-theme-op2mise-simple'} style={gridWrapperStyle(leftGrid.gridHeightBuffer)}>
            <LoaderTemplate {...{
              isLoading: isLeftGridStillProcessing || isRightGridStillProcessing,
              height: gridWrapperStyle(leftGrid.gridHeightBuffer).height,
            }} />
            <LoaderTemplateForRow {...{
              isLoading: isLeftGridRowStillProcessing && !isLeftGridStillProcessing,
              height: gridWrapperStyle(leftGrid.gridHeightBuffer + 51).height,
            }} />
            <AgGridReact
              alignedGrids={[rightGridRef]}
              columnDefs={leftGridColumnDefs}
              defaultColDef={handleDefaultColDefs(leftGrid)}
              detailCellRenderer={leftGrid.detailCellRenderer}
              detailCellRendererParams={leftGrid.detailCellRendererParams}
              detailRowHeight={leftGrid.detailRowHeight}
              getRowId={getRowId}
              groupDefaultExpanded={leftGrid.groupDefaultExpanded}
              masterDetail={leftGrid.masterDetail}
              noRowsOverlayComponent={() => (<OverlayTemplate customVerbiage={leftGrid.customVerbiage} />)}
              onColumnVisible={(params) => handleOnColumnvisible(params, 'left')}
              onFilterChanged={(params) => handleOnFilterChanged(params, 'left')}
              onFirstDataRendered={(params) => setIsLeftGridFirstDataRendered(true)}
              onGridReady={(params) => handleOnLeftGridReady(params)}
              onGridPreDestroyed={(params) => handleOnLeftGridPreDestroyed(params)}
              onSelectionChanged={(params) => handleOnSelectionChanged(params, 'left')}
              onSortChanged={(params) => handleOnSortChanged(params, 'left')}
              onStateUpdated={(params) => handleOnStateUpdated(params, 'left')}
              pagination={leftGrid.pagination}
              paginationPageSize={leftGridPageSize}
              ref={leftGridRef}
              rowData={leftGridData}
              rowClassRules={{
                ...leftGrid.rowClassRules,
                ...(leftGrid.showAlternativeRowHighlight && {
                  'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
                }),
              }}
              rowSelection={leftGrid.enableMultipleRowSelection ? 'multiple' : 'single'}
              rowMultiSelectWithClick={leftGrid.enableMultipleRowSelection}
              suppressMovableColumns={leftGrid.suppressMovableColumns}
              suppressRowClickSelection={leftGrid.useCheckboxForRowSelection}
              {...leftGrid.enableMultipleColumnSorting && { multiSortKey: 'ctrl' }}
              {...Constants.GridOptions}
            />
          </div>
          <FooterTemplate {...{
            loading: isLeftGridStillProcessing || isLeftGridRowStillProcessing,
            pagination: leftGrid.pagination,
            rowsToDisplay: leftGridRowsToDisplay,
            customFooterElement: leftGrid.customFooterElement,
            baseGridRef: leftGridRef
          }} />
          {leftGrid.showRowSelectionCount && (
            <p style={{ marginTop: '15px', color: '#6d6d73', fontSize: '12px', fontWeight: '400', letterSpacing: 0, lineHeight: '18px' }}>
              {leftGridSelectedRows} rows selected
            </p>
          )}
        </div>
        <div className='action-buttons'>
          {props.actions.map(action => {
            return (
              <Button
                onClick={action.onClick || null}
                disabled={action.disabled || false}
                icon={action.icon}
                iconPosition={action.iconPosition}
                label={action.label}
                allowTextWrap={true}
              />
            );
          })}
        </div>
        <div style={{ height: '100%', flex: 5 }}>
          <span style={{ color: '#6d6d73', fontSize: '14px', fontWeight: 400, marginBottom: '5px' }}>{rightGrid.gridLabel}</span>
          <div className={props.theme === Themes.PRIMARY ? columnIsWithChildren ? 'ag-theme-op2mise-2' : 'ag-theme-op2mise' : 'ag-theme-op2mise-simple'} style={gridWrapperStyle(leftGrid.gridHeightBuffer)}>
            <LoaderTemplate {...{
              isLoading: isLeftGridStillProcessing || isRightGridStillProcessing,
              height: gridWrapperStyle(leftGrid.gridHeightBuffer).height,
            }} />
            <LoaderTemplateForRow {...{
              isLoading: isRightGridRowStillProcessing && !(isLeftGridStillProcessing || isRightGridStillProcessing),
              height: gridWrapperStyle(leftGrid.gridHeightBuffer + 51).height,
            }} />
            <AgGridReact
              alignedGrids={[leftGridRef]}
              columnDefs={rightGridColumnDefs}
              defaultColDef={handleDefaultColDefs(rightGrid)}
              detailCellRenderer={rightGrid.detailCellRenderer}
              detailCellRendererParams={rightGrid.detailCellRendererParams}
              detailRowHeight={rightGrid.detailRowHeight}
              getRowId={getRowId}
              groupDefaultExpanded={rightGrid.groupDefaultExpanded}
              masterDetail={rightGrid.masterDetail}
              noRowsOverlayComponent={() => (<OverlayTemplate customVerbiage={rightGrid.customVerbiage} />)}
              onColumnVisible={(params) => handleOnColumnvisible(params, 'right')}
              onFilterChanged={(params) => handleOnFilterChanged(params, 'right')}
              onFirstDataRendered={(params) => setIsRightGridFirstDataRendered(true)}
              onGridReady={(params) => handleOnRightGridReady(params)}
              onGridPreDestroyed={(params) => handleOnRightGridPreDestroyed(params, rightGridRef)}
              onSelectionChanged={(params) => handleOnSelectionChanged(params, 'right')}
              onSortChanged={(params) => handleOnSortChanged(params, 'right')}
              onStateUpdated={(params) => handleOnStateUpdated(params, 'right')}
              pagination={rightGridPageSize}
              paginationPageSize={rightGrid.pageSize}
              ref={rightGridRef}
              rowData={rightGridData}
              rowClassRules={{
                ...rightGrid.rowClassRules,
                ...(rightGrid.showAlternativeRowHighlight && {
                  'ag-row-alternate': (params) => params && params.rowIndex % 2 !== 0,
                }),
              }}
              rowSelection={rightGrid.enableMultipleRowSelection ? 'multiple' : 'single'}
              rowMultiSelectWithClick={rightGrid.enableMultipleRowSelection}
              suppressMovableColumns={rightGrid.suppressMovableColumns}
              suppressRowClickSelection={rightGrid.useCheckboxForRowSelection}
              {...rightGrid.enableMultipleColumnSorting && { multiSortKey: 'ctrl' }}
              {...Constants.GridOptions}
            />

          </div>
          <FooterTemplate {...{
            loading: isLeftGridStillProcessing || isLeftGridRowStillProcessing,
            pagination: rightGrid.pagination,
            rowsToDisplay: rightGridRowsToDisplay,
            customFooterElement: rightGrid.customFooterElement,
            baseGridRef: rightGridRef
          }} />
          {rightGrid.showRowSelectionCount && (
            <p style={{ marginTop: '15px', color: '#6d6d73', fontSize: '12px', fontWeight: '400', letterSpacing: 0, lineHeight: '18px' }}>
              {rightGridSelectedRows} rows selected
            </p>
          )}
        </div>
      </div>
    </div >
  );
}

// I need a reducer/state that will allow me to update columns/rows for DualGrid/BaseGrid/and OverviewGrid